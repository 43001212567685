// Armenian
export default{
    form: {
        startSurveyButton: "Սկսենք",
        nextButton: "Հաջորդ",
        submitAnswerButton: "Ուղարկել պատասխան",
        continueButton: "Շարունակել",
        finishButton: "Ավարտել",
        headerPercentageCompletion: 'ավարտված է',
        headerQuestionsCompleted: 'հարցեր',
        headerDefaultErrorMessage: 'Ուֆֆ, ինչ-որ բան սխալ է գնացել։',
        brandingPoweredBy: 'Շարժիչը՝',
        brandingPromoWhySurvey: 'Ինչու՞ հարցում անել, եթե կարող եք օգտագործել <b>{product}</b>։',
        brandingPromoGetStarted: 'Սկսեք այսօր!',
        submissionPending: "Ձեր voiceform-ը ուղարկվում է։ Խնդրում ենք չփակել ձեր զննարկչի պատուհանը։",
        submissionCanCloseWindow: "Ձեր voiceform-ը հաջողությամբ ուղարկվեց։ Հիմա կարող եք փակել պատուհանը 👍",
        mediaAttachmentOverlayCloseButton: "Փակել",
        expiredFormHeader: "Շնորհակալություն այցելության համար!",
        expiredFormMessage: "Թվում է՝ այս հարցման ժամկետը սպառվել է։ Խնդրում ենք կապվել voiceform-ի ստեղծողի հետ՝ տեղեկացնելու համար։",
        notPublishedFormHeader: "Voiceform-ը հրապարակված չէ!",
        notPublishedFormMessage: "Թվում է՝ այս հարցումը հրապարակված չէ։ Խնդրում ենք կապվել voiceform-ի ստեղծողի հետ՝ տեղեկացնելու համար։",
        notPublishedFormRetryButton: "Փորձեք կրկին",
        errorFormHeader: "Ուֆֆ!",
        errorFormMessage: "Հնարավոր է՝ ինչ-որ բան սխալ է գնացել։ Խնդրում ենք փորձել ավելի ուշ։",
        errorFormRetryButton: "Փորձեք կրկին",
        emptyFormHeader: "Շնորհակալություն այցելության համար!",
        emptyFormMessage: "Ուֆֆ, այս ձևաթուղթը այս պահին դատարկ է, առանց հարցերի կամ լրացման դաշտերի։ Ներողություն ենք խնդրում անհարմարության համար։ Խնդրում ենք կապվել ձևաթղթի ստեղծողի հետ՝ խնդրի մասին տեղեկացնելու համար։ Շնորհակալություն ձեր ըմբռնման և համագործակցության համար։",

        restoreFormHeader: "Թվում է՝ դուք ունեք ընթացիկ ձևաթուղթ!",
        restoreFormMessage: "Ցանկանու՞մ եք շարունակել այնտեղից, որտեղ կանգնեցիք։",
        restoreFormButton: "Շարունակել ձևաթուղթը",
        restoreFormButtonNew: "Սկսել նորից",

        audioPermissionHeader: "Ցանկանու՞մ եք պատասխանել ձայնով։",
        audioPermissionMessage: "Խոսելը կդարձնի պատասխանելը արագ և հեշտ։",
        audioPermissionAcceptButton: "Այո, ուզում եմ",
        audioPermissionDenyButton: "Ոչ, շնորհակալություն",
        audioPermissionErrorHeader: "Միկրոֆոնը հասանելի չէ",
        audioPermissionErrorMessage: "Խնդրում ենք ստուգել ձեր զննարկչի թույլտվությունները՝ միկրոֆոնին հասանելիություն տրամադրելու համար։",
        audioPermissionErrorButton: "Լավ",
        audioRecorderFailedUpload: "Չհաջողվեց վերբեռնել ձայնագրությունը",
        silenceDetectorError: "Ձեր ձայնը չի լսվում։ Խնդրում ենք ստուգել ձեր միկրոֆոնի նախապատվությունները։ Հուշում՝ կարող է ձեր արտաքին միկրոֆոնն է։",
        audioRecorderButtonRecord: "Ձայնագրել",
        audioRecorderButtonRecordMore: "Ավելին ձայնագրել",
        voiceResponseSelectionMessage: "Ընտրեք, թե ինչպես եք ցանկանում պատասխանել...",
        voiceResponseSelectionOrDivider: "կամ",
        voiceResponseTextInputPlaceholder: "Մուտքագրեք պատասխան",
        voiceResponseTextInputCharacterCounterMinMessage: "Խնդրում ենք մուտքագրել առնվազն {min} նիշ",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} նիշ (նվազագույնը {min} նիշ)",
        voiceResponseTextInputCharacterValidationMessage: "{count} նիշ (նվազագույնը {min} նիշ)",

        // checkbox
        checkboxValidationTooFew: "Դուք պետք է ընտրեք առնվազն {min} տարբերակ(ներ)",
        checkboxValidationTooMany: "Դուք պետք է ընտրեք ոչ ավելի, քան {max} տարբերակ(ներ)",
        checkboxNoneOfTheAboveOption: "Ոչ մեկը վերը նշվածից",
        checkboxInvalidAnswer: "Անվավեր պատասխան։",

        // datepicker
        datePickerPlaceholder: "Ընտրեք ամսաթիվը",

        // dropdown
        // email
        emailLabel: "Էլ. փոստ",
        emailInvalid: "Անվավեր էլ. փոստ։",
        // file-upload
        fileUploadPluginNameCamera: "Տեսախցիկ",
        fileUploadPluginNameCameraVideo: "Ձայնագրել տեսանյութ",
        fileUploadPluginScreenCast: "Էկրանի ձայնագրում",
        fileUploadVideoImportFilesDefault: 'Ձայնագրեք կամ վերբեռնեք տեսանյութ հետևյալով՝',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Ընտրեք տեսանյութ ձայնագրելու տարբերակ՝',
        fileUploadVideoImportFilesNoCamera: 'Էկրանի տեսանյութ ձայնագրելու համար սեղմեք կոճակը',
        fileUploadVideoImportFilesNoScreenCast: 'Տեսանյութ ձայնագրելու համար սեղմեք կոճակը',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Տեսանյութ ձայնագրելու տարբերակ չկա',

        // matrix
        matrixValidationMessage: "Պատասխանը վավեր է։",
        matrixRow: "Տող",
        matrixColumn: "Սյունակ",
        matrixRowRequired: "Տող {rowTitle}-ն անհրաժեշտ է։",
        matrixRadioGroup: "Ռադիո խումբ",
        matrixCheckboxGroup: "Checkbox խումբ",
        matrixGroupRequired: "Տող {rowTitle}-ի համար։ {type} {groupTitle}-ն անհրաժեշտ է։",
        matrixColumnRequired: "Տող {rowTitle}-ի համար։ Սյունակ {columnTitle}-ն անհրաժեշտ է։",
        matrixColumnInvalid: "Տող {rowTitle}-ի համար։ Սյունակ {columnTitle}-ն անվավեր է։",
        matrixRequired: "Անհրաժեշտ է։",
        matrixNumericMustBeNumber: "Պետք է լինի թիվ։",
        matrixNumericMustBeGreaterThenMin: "Պետք է մեծ լինի {min}-ից։",
        matrixNumericMustBeLessThenMax: "Պետք է փոքր լինի {max}-ից։",
        matrixNumericMustBeInteger: "Պետք է լինի ամբողջ թիվ։",
        matrixNumericInvalidNumber: "Անվավեր թիվ։",

        // name
        nameLabel: "Անուն",
        nameInvalid: "Մուտքագրեք անուն և ազգանուն։",

        // nps
        npsNotLikely: "Շատ քիչ հավանական է",
        npsExtremelyLikely: "Շատ հավանական է",

        // numeric input
        numericInputRequired: "Անհրաժեշտ է։",
        numericInputMustBeNumber: "Պետք է լինի թիվ։",
        numericInputMustBeGreaterThenMin: "Պետք է մեծ լինի {min}-ից։",
        numericInputMustBeLessThenMax: "Պետք է փոքր լինի {max}-ից։",
        numericInputMustBeInteger: "Պետք է լինի ամբողջ թիվ։",
        numericInputInvalidNumber: "Անվավեր թիվ։",
        numericInputPlaceholder: "Մուտքագրեք թիվ",

        // phone
        phoneInvalid: "Հեռախոսահամարը անվավեր է։",
        phoneCountrySelectorLabel: 'Երկրի կոդ',
        phoneCountrySelectorError: 'Ընտրեք երկիր',
        phoneNumberLabel: 'Հեռախոսահամար',
        phoneExample: 'Օրինակ՝',

        // boolean
        booleanYesLabel: "Այո",
        booleanNoLabel: "Ոչ",

        //questionStep
        questionStepAudioQuestionLabel: "Ձայնային հարց",

        // errors
        invalidPhoneNumber: "{phone} անվավեր հեռախոսահամար է։",
        invalidEmail: "{email} անվավեր էլեկտրոնային հասցե է։",
        questionIsRequired: "Հարցը պարտադիր է։",
        answerIsNotValid: "Պատասխանը անվավեր է։",
        unfinishedProbingDialogError: "Խնդրում ենք ավարտել երկխոսությունը։",
        cannotResumePartialResponse: "Չի հաջողվում վերսկսել մասնակի պատասխանը։",
        failedToSubmitForm: "Ձևաթուղթը չհաջողվեց ուղարկել։ Խնդրում ենք ստուգել ձեր ինտերնետ կապը և փորձել կրկին։",

        //language picker
        searchLanguage: "Փնտրել լեզու",
    }
}
